<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
//import router from "@/router/index.js";
//import { setTimeout } from "timers";
// If you need to reference 'L', such as in 'L.icon', then be sure to
// explicitly import 'leaflet' into your component
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Required for side-effects
require("firebase/firestore");

/**
 * Advanced table component
 */
export default {
    page: {
        title: "Trajets en cours",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
        Layout,
        PageHeader,
        LMap,
        LTileLayer,
        LMarker,
    },
    data() {
        return {
            tableData: [],
            title: "Suivi de trajet",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Suivi de trajet",
                    active: true,
                },
            ],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            map: null,
            idtrajet: 0,
            markerLatLng: [6.3633, 2.4300],
            center: [6.3633, 2.4300],
            zoom:10,

            lat: 6.3633,
            lng: 2.4300,

        };
    },
    computed: {
        
    },
    mounted() {
       

        let coords = [this.lat, this.lng]
        this.$refs.mymap.mapObjectmap = L.map('map').setView(coords, 13);
       
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">Openstreetmap</a> contributors'
        }).addTo(this.map);
    },

    methods: {

    //     getAll() {
    //   this.notif = [];
    //   this.db
    //     .collection("notifdash")
    //     .orderBy("dateNotif", "desc")
    //     .limit(this.numberOfNotif)
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         var data = doc.data();
    //         if (data.seen == false) {
    //           this.total++;
    //           this.notif.push({
    //             id: doc.id,
    //             idUser: data.idUser,
    //             textNotif: data.textNotif,
    //             seen: data.seen,
    //             typeNotif: data.typeNotif,
    //             dateNotif: formatDistance(
    //               new Date(data.dateNotif.toDate()),
    //               new Date(),
    //               { addSuffix: true },
    //               { locale: "fr_FR" }
    //             ),
    //           });
    //         }
    //       });
    //     });

    //   /*   setTimeout(() => {
    //       this.totalNotif = this.notif.length;
    //      
    //     }, 4000); */

    // },


    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div id="map" style="height: 500px">
            <l-map style="height: 500px" :zoom="zoom" :center="center" ref="mymap">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>
        </div>
    </Layout>
</template>

<style></style>